<template>
  <Transition>
    <router-view />
  </Transition>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  data() {
    return {
      router: useRouter(),
      store: useStore(),
    };
  },
  mounted() {
    const token = localStorage.getItem("authToken");
    if (token) {
      this.router.push("/user");
    } else {
      this.router.push("/");
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

:root {
  --mainBlue: #7aa6c7;
  --darkBlue: #5784ba;
  --pink: #f4cfdf;
  --softBlue: #b6d8f2;
  --yellow: #f7f6cf;
  --carbon: #232222;
  --orange: #f3b685;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--carbon);
}

.headers {
  color: var(--carbon);
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}

.labelInputs {
  color: var(--carbon);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.btnSubmit {
  background-color: var(--yellow);
  padding: 5px 10px;
  border: solid 1px var(--yellow);
  border-radius: 15px;
  transition: 0.5s;
}

.btnSubmit:hover {
  background-color: white;
  border: solid 1px var(--carbon);
}

.btnSecondary {
  background-color: transparent;
  padding: 5px 10px;
  border: solid 1px transparent;
  border-radius: 15px;
  transition: 0.5s;
}

.btnSecondary:hover {
  border: solid 1px var(--carbon);
}

/* ANIMATIONS */
@keyframes bounce1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes entrance1 {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(-250px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(-65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}
@keyframes show1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes shake1 {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateX(-2px);
  }

  20%,
  40%,
  60% {
    transform: translateX(2px);
  }

  80% {
    transform: translateX(1px);
  }

  90% {
    transform: translateX(-1px);
  }
}

@keyframes zoomIn {
  0% {
    font-size: 100%;
  }
  100% {
    font-size: 120%;
  }
}

@keyframes wrongAlert {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* TRANSITIONS */
.bounce-enter-active {
  animation: bounce-in 1.5s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.bounce-enter-active {
  animation: bounce-in 1.2s;
}
.bounce-leave-active {
  animation: bounce-in 1.2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
