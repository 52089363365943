import { createStore } from "vuex";
import { auth } from "../firebase/index";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { db } from "../firebase/index";

export default createStore({
  state: {
    user: null,
    loading: false,
    moods: [
      {
        value: "happy",
        label: "Happy",
        icon: "fa-regular fa-face-smile",
        color: "#20DFFF",
        active: false,
      },
      {
        value: "sad",
        label: "Sad",
        icon: "fa-regular fa-face-sad-tear",
        color: "#9C7EB8",
        active: false,
      },
      {
        value: "angry",
        label: "Angry",
        icon: "fa-regular fa-face-angry",
        color: "#FB806F",
        active: false,
      },
      {
        value: "hungry",
        label: "Hungry",
        icon: "fa-regular fa-face-grin-tongue",
        color: "#FC8CB4",
        active: false,
      },
    ],
  },
  getters: {},
  mutations: {
    setUser(state, payload) {
      state.user = payload;
      state.loading = false;
    },
  },
  actions: {
    async login(context, { email, password }) {
      try {
        const response = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        if (response) {
          const token = response.user.uid;
          localStorage.setItem("authToken", token);
          const usersCollection = db.collection("users");
          try {
            const userDocRef = await usersCollection
              .doc(response.user.uid)
              .get();
            if (userDocRef.exists) {
              const userData = userDocRef.data();
              context.commit("setUser", userData);
            } else {
              //console.log("No such document!");
            }
          } catch (error) {
            //console.error("Error retrieving user data:", error);
          }
        }
      } catch (error) {
        throw new Error("login failed");
      }
      /* const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit("setUser", response.user);
      } else {
        throw new Error("login failed");
      } */
    },
    async logout(context) {
      await signOut(auth);
      context.commit("setUser", null);
      localStorage.removeItem("authToken");
    },
    async fetchUserInfo(context, token) {
      const usersCollection = db.collection("users");
      try {
        const userDocRef = await usersCollection.doc(token).get();
        if (userDocRef.exists) {
          const userData = userDocRef.data();
          console.log(userData);
          context.commit("setUser", userData);
        } else {
          //console.log("No such document!");
        }
      } catch (error) {
        //console.error("Error retrieving user data:", error);
      }
    },
  },
  modules: {},
});
