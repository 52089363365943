<template>
  <div class="" role="document">
    <div class="">
      <div class="">
        <img
          :src="currentUser.icon"
          alt="avatar"
          class="rounded-circle img-responsive userIcon"
        />
      </div>
      <div class="text-center mb-1">
        <h5 class="mt-1 mb-2 text-capitalize">Hello {{ currentUser.user }}!</h5>
        <div class="md-form ml-0 mr-0" :class="feedback">
          <input
            type="password"
            id="userPassword"
            class="form-control form-control-sm ml-0"
            :class="invalidInput"
            v-model="currentUser.password"
            :disabled="loading"
            @keyup.enter="loginAction"
          />
          <label
            data-error="wrong"
            data-success="right"
            for="userPassword"
            class="ml-0 labelInputs"
            v-if="!loading"
          >
            <p v-show="!wrongInput">
              Enter your password
              <i class="fa-sharp fa-solid fa-heart"></i>
            </p>
            <p class="text-danger alertText" v-show="wrongInput">
              <span class="text-capitalize">{{ currentUser.user }}</span>
              {{ loginErrorMsg }}
              <i class="fa-sharp fa-solid fa-exclamation"></i>
            </p>
          </label>
        </div>
        <div class="text-center mt-4">
          <button class="btnSubmit" @click="loginAction">
            Login
            <i class="fas fa-sign-in ml-1" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth } from "@/firebase";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "LoginWizard",
  props: {
    user: String,
  },
  data() {
    return {
      currentUser: {
        user: this.user,
        icon: null,
        password: null,
        mail: "",
      },
      wrongInput: false,
      loading: false,
      feedback: "",
      invalidInput: "",
      store: useStore(),
      router: useRouter(),
      loginErrorMsg: "",
    };
  },
  mounted() {
    if (this.user == "clark") {
      this.currentUser.icon = require("@/assets/img/cactus.png");
      this.currentUser.mail = "alfredoclarkcardenas@gmail.com";
    } else if (this.user == "nina") {
      this.currentUser.icon = require("@/assets/img/strawberry.png");
      this.currentUser.mail = "nenapemika@gmail.com";
    }
  },
  methods: {
    loginAction() {
      this.wrongInput = false;
      this.loading = true;
      this.feedback = "";
      this.invalidInput = "";
      if (
        this.currentUser.password == null ||
        this.currentUser.password === ""
      ) {
        this.wrongInput = true;
        this.loading = false;
        this.feedback = "badFeedback";
        this.invalidInput = "is-invalid";
        this.loginErrorMsg = "enter the password";
      } else {
        this.handleSubmit();
      }
    },
    async handleSubmit() {
      try {
        await this.store.dispatch("login", {
          email: this.currentUser.mail,
          password: this.currentUser.password,
        });
        this.loading = false;
        this.invalidInput = "is-valid";
        this.currentUser.password = "";
        this.router.push("/user");
      } catch (err) {
        this.wrongInput = true;
        this.loading = false;
        this.feedback = "badFeedback";
        this.invalidInput = "is-invalid";
        this.loginErrorMsg = "enter the right password";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.userIcon {
  height: 100px;
  background-color: white;
  margin-bottom: 10px;
  padding: 15px;
}

.userIcon:hover {
  animation: shake1 infinite 2s;
}
.btnSubmit {
  width: 100%;
  color: var(--carbon);
}
.badFeedback {
  animation: wrongAlert 0.5s ease-in;
}

.inputError {
  color: red;
}

.fa-heart {
  animation: heartbeat 1s infinite;
}
</style>
