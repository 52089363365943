<template>
  <component class="landing" :is="activeComponent"></component>
</template>

<script>
// @ is an alias to /src
import HomeScreen from "@/components/HomeScreen.vue";
import LoginWizard from "@/components/LoginWizard.vue";

export default {
  name: "LoginPage",
  components: {
    HomeScreen,
    LoginWizard,
  },
  data() {
    return {
      activeComponent: "HomeScreen",
      show: true,
    };
  },
};
</script>

<style scoped></style>
