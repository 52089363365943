<template>
  <section id="loginSection">
    <Transition name="bounce">
      <div v-if="!showLogin" class="container-fluid">
        <div class="row vh-100 gridUsers">
          <div @click="login('clark')" class="col-lg-6 col-xs-12 clarkSide">
            <h3>Clark</h3>
          </div>
          <div @click="login('nina')" class="col-lg-6 col-xs-12 ninaSide">
            <h3>Nina</h3>
          </div>
        </div>
        <div id="middleBox" class="textbox position-absolute">
          <h3>Who are you?</h3>
        </div>
      </div>
      <div v-else class="container-fluid">
        <div class="row vh-100">
          <div class="col-12 loginDisplay" :class="loginTheme">
            <div class="loginComponent">
              <LoginWizard :user="currentUser" />
              <button @click="backAction" class="btnSecondary">
                <i class="fa-solid fa-arrow-left"></i>
                Go back
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script>
// @ is an alias to /src
import LoginWizard from "@/components/LoginWizard.vue";

export default {
  name: "LoginPage",
  components: {
    LoginWizard,
  },
  data() {
    return {
      showLogin: false,
      currentUser: null,
      loginTheme: null,
    };
  },
  methods: {
    login(user) {
      this.showLogin = true;
      this.currentUser = user;
      if (user == "clark") {
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", "#7aa6c7");
        document.getElementById("loginSection").style.backgroundColor =
          "var(--mainBlue)";
        document.getElementById("middleBox").style.opacity = "0";
        this.loginTheme = "clarkSide";
      } else if (user == "nina") {
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", "#f4cfdf");
        document.getElementById("loginSection").style.backgroundColor =
          "var(--pink)";
        document.getElementById("middleBox").style.opacity = "0";
        this.loginTheme = "ninaSide";
      }
    },
    backAction() {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", "#7aa6c7");
      this.showLogin = false;
    },
  },
  mounted() {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#7aa6c7");
  },
};
</script>

<style scoped>
#loginSection {
  background-color: transparent;
}
.gridUsers {
  cursor: pointer;
}
.clarkSide {
  background-color: var(--mainBlue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-lg-6 {
  transition: 0.3s ease;
}

.col-lg-6:not(:hover) {
  /* opacity: 0.9; */
  font-size: 100%;
}
.col-lg-6:hover {
  font-size: 120%;
}
.ninaSide {
  background-color: var(--pink);
  display: flex;
  align-items: center;
  justify-content: center;
}
.textbox {
  background-color: #f5f5f5;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  transition: opacity 0.1s;
}

.loginDisplay {
  display: flex;
  flex-direction: column;
}

.loginComponent {
  width: auto;
}

.btnSecondary {
  margin-top: 10px;
  width: 100%;
  color: var(--carbon);
}

@media (max-width: 767px) {
  .textbox {
    transform: translate(-50%, 0);
  }
}
</style>
