/* eslint-disable prettier/prettier */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBnQdJmL6klCva4xkjyZ7VaGHbp1wIvKss",
    authDomain: "nina-clark.firebaseapp.com",
    projectId: "nina-clark",
    storageBucket: "nina-clark.appspot.com",
    messagingSenderId: "550731442716",
    appId: "1:550731442716:web:ea89addd7f057fb8864dd9",
    measurementId: "G-2J1RRLTCZ1"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore()

export { auth, db }